import { useState } from "react";
import toast from "react-hot-toast";
import { useMediaQuery } from "@mui/material";

import { GilroyFontDark } from "@lib/GilroyFont";

import TextSlider from "./TextSlider";
import "react-phone-number-input/style.css";
import useMouseOver from "@hooks/useMouseOver";
import { useRouter } from "next/router";

import {
  options,
  BudgetValues,
  initialValues,
  textSliderData,
} from "../../data/formData";
import useDynamicFormik from "./../../hooks/useDynamicFormik";
import { formValidationSchema } from "../../utils/formValidations";
import InputComponent from "../InputComponent";
import SelectComponent from "../SelectComponent";

import {
  sendEmail,
  sendEmailToCustomer,
  sendDataToSheets4,
} from "./../../lib/sendEmail";
import { sendLeadEvent } from "../../utils/facebookEvent";
import AfterFormSubmit from "../AfterFormSubmit";
import timeStampGenerator from "./../../lib/timeStampGenerator";
import PhoneInputCustom from "./../PhoneInputCustom";

import useClientIP from "@hooks/useClientIP";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import Link from "next/link";

const HeroForm = ({ isVisible, close, heading, subheading }) => {
  const isMobile = useMediaQuery("(max-width:786px)");
  const isPixelActive = useMouseOver();
  const router = useRouter();

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionBudget, setSelectedOptionBudget] = useState(null);
  const [selectProjectBegin, setSelectProjectBegin] = useState(null);
  const [selectHiringDecision, setSelectHiringDecision] = useState(null);

  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");
  const ipAddress = useClientIP();
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);

  };

  async function sendconvertionEvent(eventName, eventData) {
    try {
      const response = await axios.post('/api/send-event', {
        eventName,
        userData: eventData.userData,
        customData: eventData.customData,
        clickId: eventData.clickId,
        userAgent: eventData.userAgent,
        browserId: eventData.browserId,
        externalId: eventData.externalId,
        ipAddress: eventData.ipaddress,
      });
  
      console.log('Event sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending event:', error.response ? error.response.data : error.message);
    }
  }
  function getBrowserId() {
    // Logic to get Facebook Browser ID (fbp)
    const fbpCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('_fbp='));
    return fbpCookie ? fbpCookie.split('=')[1] : undefined;
  }

  function getClickId() {
    // Check for _fbc cookie
    const fbcCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('_fbc='));
  
    if (fbcCookie) {
      console.log('Returning _fbc from cookie:', fbcCookie.split('=')[1]);
      return fbcCookie.split('=')[1];
    } else {
      console.warn('No _fbc cookie found');
    }
  
    // Generate _fbc from fbclid query parameter if _fbc cookie does not exist
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');
    if (fbclid) {
      const subdomainIndex = 1; // Adjust this based on your domain structure
      const creationTime = Math.floor(new Date().getTime() / 1000); // Use current time as creation time
      const generatedFbc = `fb.${subdomainIndex}.${creationTime}.${fbclid}`;
      console.log('Generated _fbc from fbclid:', generatedFbc);
      return generatedFbc;
    } else {
      console.warn('No fbclid query parameter found');
    }
  
    // Generate a fallback _fbc value
    const subdomainIndex = 1;
    const creationTime = Math.floor(new Date().getTime() / 1000);
    const placeholderFbclid = '0000000000000000'; // Placeholder if no fbclid is found
    const fallbackFbc = `fb.${subdomainIndex}.${creationTime}.${placeholderFbclid}`;
    console.log('Generated fallback _fbc:', fallbackFbc);
    return fallbackFbc;
  }

  const onSubmit = async (values) => {
    const form_service_type =
      selectedOption?.map((option) => option.label).join(", ") ||
      "";
    const form_service_type_budget = selectedOptionBudget?.value
      ? selectedOptionBudget.value
      : "";

    const form_service_type_projectBegin = selectProjectBegin?.value
      ? selectProjectBegin.value
      : "";

    const form_service_type_hiringDecision = selectHiringDecision?.value
      ? selectHiringDecision.value
      : "not selected";

    try {


      const utcMinus11Timestamp = timeStampGenerator();
      let split_data = utcMinus11Timestamp.split(', ')
let date_str = split_data[0]
let time_str = split_data[1]

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumber;
};

const formattedPhoneNumber = formatPhoneNumber(formik.values.phone);
const userAgent = navigator.userAgent;
      const clickId = getClickId(); // Replace with your actual click ID logic
      const browserId = getBrowserId(); // Function to get Facebook Browser ID (fbp)
      const externalId = uuidv4(); // Replace with your actual external ID logic
      const ipaddress = ipAddress; 



if (isChecked) {

  await sendconvertionEvent('Lead', {
    userData: {
      fn: values.name,
      email: values.email,
      ph: formattedPhoneNumber,
    },
    customData: {
      content_type: 'Content Form Submit',
      page_path: router.pathname,
    },
    clickId,
    userAgent,
    browserId,
    externalId,
    ipaddress,
  });


    //  sendLeadEvent(
    //    "Lead",
    //    formik,
    //    "Content Form Submit",
    //    router.pathname,
    //    isPixelActive,
        
    //  );

      const newRow = {
        Date: date_str,
         // Time: time_str,
         State: "",
         Source: "Get Started",
         Assigned: "",
         Status: "Fresh",
         Name: formik.values.name,
         Email: formik.values.email,
         Number: formattedPhoneNumber,
         Company: "",
         "Client\nNotes": `${form_service_type_budget} ${form_service_type ? ('/') : ('')}  ${form_service_type} ${formik.values.message ? ('/') : ('')} ${formik.values.message} ${ipAddress ? ('/') : ('')} ${ipAddress}`,
         // Services: form_service_type,
         // Message: formik.values.message,
         // IP: ipAddress,
      };

      const gSheets = await sendDataToSheets4(newRow);
      router.push('/thankyou');
      formik.setStatus({
        success: true,
        message: "Form submitted successfully",
      });
      

      if (!isVisible) {
        close(false);
      }
      setIsFormSubmited(true);
      if (!isVisible) {
        close(false);
      }
      
      const emailResponse = await sendEmail(
        formik,
        form_service_type_budget,
        form_service_type,
        form_service_type_projectBegin,
        form_service_type_hiringDecision,
        newRow,
        ipAddress,
      );

      const sendEmailToUser = await sendEmailToCustomer(
        formik,
        form_service_type_budget,
        form_service_type,
        form_service_type_projectBegin,
        form_service_type_hiringDecision,
        newRow,
      );

      if (emailResponse.data === "Success") {
        // toast.success("Thank you!, Our representative will contact You.");
        formik.setStatus({
          success: true,
          message: "Form submitted successfully",
        });

        if (!isVisible) {
          close(false);
        }
        setIsFormSubmited(true);
        if (!isVisible) {
          close(false);
        }
      } else {
        formik.setStatus({ success: false, message: "Submission error" });

        toast.error("Please Retry again.");
      }
    } else {console.log("Select the checkbox")}


    } catch (error) {
      /* eslint-disable */
      console.log(error.message);
      formik.setStatus({ success: false, message: error.message });
    }
  };

  const formik = useDynamicFormik(
    initialValues,
    formValidationSchema,
    onSubmit,
  );

 

  console.log(formik);

  return (
    <div>
      <div className=" flex flex-col  py-10  mx-auto pb-[5rem] pl-mobile">
        
        <form
          onSubmit={formik.handleSubmit}
          className=" sm:p-6 w-full flex justify-between   mt-10 relative"
        >
          <div className="slider-object-hero">
            <div className="relative flex min-w-auto">
              <img
                className="h-auto slider-object-image"
                src="/hero-banner-text.svg"
                alt="hero banner image"
              />
              <TextSlider items={textSliderData} font={GilroyFontDark} />

              <div className="flex justify-start items-end mb-4 gap-6 pl-6">
                <Link target="_blank" href={'https://www.google.com/search?gs_ssp=eJzj4tVP1zc0LEsyqEw3T8s2YLRSMahINLU0NEyxMEkyT0oxNjUxtDKoMDdKszQ1szA1NzdJMTA39-IpSU3OSE7NSS1KzCsBAHJXE0E&q=techcelerant&oq=techcele&gs_lcrp=EgZjaHJvbWUqDQgCEC4YrwEYxwEYgAQyBggAEEUYPDIGCAEQRRg8Mg0IAhAuGK8BGMcBGIAEMgYIAxBFGDkyBwgEEAAYgAQyBwgFEAAYgAQyBggGEEUYPDIGCAcQRRg80gEINDk4MmowajGoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0xa5911d84b7bd3541:0x72f95685774d077,1,,,,'}>
              <img
                className="h-20 w-40"
                src="/rev4.png"
                alt="hero banner image"
              />
              </Link>
              <Link target="_blank" href={'https://www.trustpilot.com/review/techcelerant.com'}>
              <img
                className="h-20 w-40"
                src="/rev5.png"
                alt="hero banner image"
              />
              </Link>
              </div>
            </div>
          </div>

          {formik?.status?.success === true ? (
            <></>
          ) : (
            <div className="absolute right-10 mt-[3rem] hero-form-container  flex sm:justify-center items-center ">
              <div className="flex flex-col  items-start justify-center  sm:min-w-[35vw] min-w-[20vw] max-w-[100vw] sm:max-w-[35vw] bg-white z-10 px-10 py-4  rounded-2xl ">
              <label className="w-[100%]">
                <InputComponent
                  required={true}
                  className=" border-b-[5px] border-gray-300 w-[15rem] sm:w-[100%]  text-black outline-none "
                  value="name"
                  placeholder="Full Name"
                  formik={formik}
                />
                </label>
                <label className="w-[100%]">

                <InputComponent
                  required={true}
                  className=" border-b-[5px] border-gray-300 w-[15rem] sm:w-[100%]  text-black outline-none"
                  value="email"
                  placeholder="Email"
                  formik={formik}
                />
                </label>
                <label className="w-[100%]">
                <PhoneInputCustom
                  formik={formik}
                  className="w-[15rem] border-b-[5px] border-gray-300 sm:w-[20rem]  text-black outline-none"
                />
                </label>

                {isVisible && (
                  <>
                  <label className="w-[100%]">
                    <SelectComponent
                      className="mb-2 w-[100%] -ml-[3px] border-b-[5px] border-gray-300"
                     // required={true}
                      selectWidth="100%"
                      isMobile={isMobile}
                      defaultValue={formik.values["selectedOption"]}
                      //value="selectedOption"
                      onChange={(selectedOption) => {
                        formik.setFieldValue(
                          "selectedOption",
                          selectedOption
                            ? selectedOption.map((option) => option.value)
                            : [],
                        );
                        setSelectedOption(selectedOption);
                      }}
                      formikTouched={formik.touched["selectedOption"]}
                      formikError={formik.errors["selectedOption"]}
                      options={options}
                      placeholder="Choose Services"
                      aria-label="Choose Services"
                      instanceId="react-select-services5"
                      multi={true}
                      mobileWidth="auto"
                      formik={formik}
                      value={selectedOption}
                    />
                    </label>
                    <label className="w-[100%]">
                    <SelectComponent
                      className="mb-2 w-[100%] -ml-[3px] border-b-[5px]  border-gray-300"
                      //required={true}
                      selectWidth="100%"
                      isMobile={isMobile}
                      defaultValue={formik.values["selectedOptionBudget"]}
                      onChange={(selectedOptionBudget) => {
                        formik.setFieldValue(
                          "selectedOptionBudget",
                          selectedOptionBudget
                            ? selectedOptionBudget.value
                            : "",
                        );
                        setSelectedOptionBudget(selectedOptionBudget);
                      }}
                      stringValue="selectedOptionBudget"
                      options={BudgetValues}
                      placeholder="Budget"
                      instanceId="react-select-budget6"
                      label="budget"
                      multi={false}
                      mobileWidth="auto"
                      formik={formik}
                      value={selectedOptionBudget}
                      formikTouched={formik.touched["selectedOptionBudget"]}
                      formikError={formik.errors["selectedOptionBudget"]}
                    />
                    </label>

                    <div className="mb-2 col-span-2 w-[100%]">
                    <label className="w-[100%]">
                      <textarea
                        name="message"
                        id="message7"
                        className=" w-[15rem] sm:w-[100%] h-[100px]  border border-gray-300 px-2 py-[5px]  rounded-2xl bg-white input-placeholder text-[13px]"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        rows={4}
                        placeholder="Type your message here"
                        onBlur={formik.handleBlur}
                      />
                      </label>
                      {formik.touched.message && formik.errors.message ? (
                        <div className="error">{formik.errors.message}</div>
                      ) : null}
                    </div>
                    <div className="mb-2 col-span-2">
                <label>
            <input className=" "
             type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              required
            />
            <span className="ml-2 text-xs text-gray-700">
            I hereby give my consent to Techcelerant to use my number for sending me SMS messages
        </span>
      </label>
                </div>

                    <div style={{ display: "flex" }}></div>
                  </>
                )}

                {/* <div style={{ paddingBottom: '5px', display: 'flex', justifyContent: 'start' }}>
              <ReCAPTCHA
                sitekey={`${process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY}`} // Replace with your site key
                  onChange={onRecaptchaChange}

                          />
                   </div> */}

                {isFormSubmited ? (
                  <button
                    disabled
                    type="submit"
                    className={`relative w-[15rem]  cursor-not-allowed	 sm:min-w-[100%] h-[2.5rem] bg-gray-300 shadow-lg shadow-gray-500 text-black rounded-full cursor-pointer ${
                      isVisible === false ? "mt-5" : ""
                    }`}
                  >
                    Sent
                  </button>
                ) : (
                  <button
                    type="submit"
                    className={`relative w-[15rem] sm:min-w-[100%] h-[2.5rem] ${formik.isValid && !formik.isSubmitting ? "  form-buttom-submit  bg-violet-400" : "bg-gray-300"} text-white rounded-full cursor-pointer`}
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Sending..." : "Submit"}
                  </button>
                )}
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default HeroForm;
