import { useState, useEffect } from "react";

const TextSlider = ({ items, font }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentItemIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [items]);

  const transformStyle = `translateX(-${currentItemIndex * 5}%)`;

  return (
    <h3
      className={`slider-object-image-h3 ${font.className} slideTransition`}
      dangerouslySetInnerHTML={{ __html: items[currentItemIndex].text }}
      style={{ transform: transformStyle }}
    ></h3>
  );
};

export default TextSlider;
